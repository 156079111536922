<template>
  <div class="px-1">
    <div class="mb-10 text-subtitle-2">
      Вы выбрали: {{ $store.getters.getActiveSpecialtyName }}
    </div>
    <h2 class="text-h5 text-center mb-10">Выберите доктора</h2>
    <doctor-item
      v-for="doctor in doctors"
      :key="doctor.id"
      :doctor="doctor"
      class="mb-5"
      @click-time="clickTimeHandler"
    ></doctor-item>
    <v-dialog
      v-if="timeItemDialog"
      v-model="timeItemDialog"
      scrollable
      width="100%"
      max-width="600px"
    >
      <v-card class="doctor-item-selected">
        <v-card-title
          class="justify-space-between flex-nowrap align-start align-md-center"
        >
          <span class="text-break" style="font-weight: 600">
            Вы записываетесь на прием к специалисту:
          </span>
          <v-icon class="mr-n4 mr-md-0" @click="timeItemDialog = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <div
            class="d-flex flex-column flex-sm-row align-center align-sm-start"
          >
            <v-avatar class="mr-2" size="110" tile>
              <v-img
                :src="
                  activeDoctor.photo_base64
                    ? activeDoctor.photo_base64
                    : require('../assets/blank.jpg')
                "
              ></v-img>
            </v-avatar>
            <div>
              <v-card-title class="pt-0 black--text text-break">
                {{ activeDoctor.name }}
              </v-card-title>
              <v-card-subtitle class="grey--text">
                {{ activeDoctor.employee_dto.specialization.name }}
              </v-card-subtitle>
              <v-card-text class="pb-0">
                <long-text :text="activeDoctor.description"></long-text>
                <div class="mt-3 doctor-item-selected__text">
                  <v-icon class="mr-2"> mdi-map-marker-outline </v-icon>
                  {{ getBranchNameById(activeDoctorTimeItem.branchid) }}
                </div>
                <div class="d-flex flex-wrap doctor-item-selected__text">
                  <span class="mr-5 mt-2">
                    <v-icon class="mr-2"> mdi-calendar-month </v-icon>
                    {{ getDMYFormattedDate(activeDoctorTimeItem.start) }}
                  </span>
                  <span class="mt-2">
                    <v-icon class="mr-2"> mdi-clock-time-two-outline </v-icon>
                    {{ getFormattedTime(activeDoctorTimeItem.start) }}
                  </span>
                </div>
              </v-card-text>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center pb-5">
          <v-btn
            color="primary"
            large
            min-width="180px"
            @click="confirmedTimeHandler"
          >
            Подтвердить <v-icon> mdi-chevron-right </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DoctorItem from "./DoctorItem.vue";
import LongText from "./LongText";
import { api } from "../api";
import moment from "moment";
import { DoctorItemModel } from "../store/models";

export default {
  name: "MisDoctors",

  components: { LongText, DoctorItem },

  data: () => ({
    doctors: [],
    timeItemDialog: false,
    activeDoctorTimeItem: {},
  }),
  computed: {
    activeDoctor() {
      return this.doctors.filter(
        (doctor) => doctor.employeeid === this.activeDoctorTimeItem.employeeid
      )[0];
    },
  },
  methods: {
    clickTimeHandler(time) {
      this.timeItemDialog = true;
      this.activeDoctorTimeItem = time;
      this.$store.commit("updateTicket", {
        doctorName: this.activeDoctor.name,
        doctorId: time.employeeid,
        dateFrom: time.start,
        dateTo: time.end,
        address: this.getBranchNameById(time.branchid),
        scheduleid: time.scheduleid,
      });
    },
    confirmedTimeHandler() {
      this.timeItemDialog = false;
      this.$emit("next");
    },
    getBranchNameById(branchId) {
      return this.$store.state.branches.filter(
        (branch) => branch.id === branchId
      )[0].address;
    },
    getDMYFormattedDate(date) {
      return moment(date).locale("ru").format("DD MMMM YYYY");
    },
    getFormattedTime(date) {
      return moment(date).format("HH:mm");
    },
    getEmployeeIds() {
      return this.doctors.map((d) => d.employeeid);
    },
  },
  mounted() {
    if (!this.$store.state.ticket.specialtyId)
      return this.$store.commit("setStep", 1);

    this.doctors = [];
    api.getDoctors(this.$store.state.ticket.specialtyId).then((res) => {
      this.doctors = res;
      if (this.doctors.length > 1) {
        this.doctors.unshift(
          DoctorItemModel({
            employeeid: this.getEmployeeIds(),
            name: "Не имеет значения",
            employee_dto: {
              specialization: {
                name: "Будет показано свободное расписание всех врачей",
              },
            },
          })
        );
      }
    });
  },
};
</script>
