export const OptionsModel = ({
  doctor_period = "14",
  verify_sms = false,
  hide_sex_and_age = "0",
  email_required = "0",
}) => {
  return {
    doctor_period,
    verify_sms,
    hide_sex_and_age,
    email_required,
  };
};

export const DoctorItemModel = ({
  id = null,
  employeeid = "", //or Array
  photo_base64 = "",
  name = "",
  description = "",
  employee_dto = {
    specialization: {
      name: "",
    },
  },
}) => {
  return {
    id,
    employeeid,
    photo_base64,
    name,
    description,
    employee_dto,
  };
};

export const TicketModel = ({
  specialtyId = null,
  doctorId = null,
  scheduleid = null,
  doctorName = "",
  dateFrom = null,
  dateTo = null,
  address = "",
}) => {
  return {
    specialtyId,
    scheduleid,
    doctorId,
    doctorName,
    dateFrom,
    dateTo,
    address,
  };
};

export const AppointmentModel = ({
  employeeid = null,
  appointment_datefrom = null,
  appointment_dateto = null,
  appointment_scheduleid = null,
  patient_lastname = "",
  patient_firstname = "",
  patient_middlename = "",
  patient_birthday = "",
  phone_number = "",
  patient_email = "",
  appointment_comment = "",
}) => {
  return {
    employeeid,
    appointment_datefrom,
    appointment_dateto,
    appointment_scheduleid,
    patient_lastname,
    patient_firstname,
    patient_middlename,
    patient_birthday,
    phone_number,
    patient_email,
    appointment_comment,
  };
};
